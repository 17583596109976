import {toastContainerStyle} from '_assets/global/jss/components/Toasts/toastContainerStyle';
import '_assets/global/scss/material-dashboard-pro-react.scss?v=1.5.0';
import ScrollToTopRoute from '_helpers/global/ScrollToTopRoute';
import i18nProvider from '_helpers/global/i18n';
import {browserHistoryAccessor} from 'App/history';
import AdminLayout from 'App/layouts/global/AdminLayout';
import AuthLayout from 'App/layouts/global/AuthLayout';
import ActivationPage from 'App/views/global/auth/ActivationPage';
import ActivationResetPasswordPage from 'App/views/global/auth/ActivationResetPasswordPage';
import ForgottenPasswordPage from 'App/views/global/auth/ForgottenPasswordPage';
import React from 'react';
import Favicon from 'react-favicon';
import {connect} from 'react-redux';
import {Redirect, Router, Switch} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {compose} from 'recompose';
import {I18nProvider} from '@lingui/react';
import {withStyles} from '@material-ui/core/styles';

const App = ({classes, globalSettings}) => {
  const activeLang = globalSettings.currentLanguage ? globalSettings.currentLanguage : 'en';

  i18nProvider.activate(activeLang);

  const isActivationPage = window.location.pathname.includes('/activation');

  return (
    <I18nProvider i18n={i18nProvider}>
      <Favicon
        url={
          process.env.PUBLIC_URL +
          `${isActivationPage ? '/activation-favicon.png' : '/favicon.ico'}`
        }
      />
      <ToastContainer
        position="top-right"
        hideProgressBar
        pauseOnHover
        draggable
        newestOnTop={true}
        pauseOnVisibilityChange
        className={classes.containerStyle}
      />
      <Router history={browserHistoryAccessor}>
        <Switch>
          {/* Auth */}
          <ScrollToTopRoute path={'/auth'} component={AuthLayout} />

          {/* Admin */}
          <ScrollToTopRoute path={'/admin'} component={AdminLayout} />

          {/* Activation */}
          <ScrollToTopRoute exact={true} path={'/activation'} component={ActivationPage} />
          <ScrollToTopRoute
            path={'/activation/reset-password'}
            component={ActivationResetPasswordPage}
          />
          <ScrollToTopRoute path={'/forgotten-password'} component={ForgottenPasswordPage} />

          <Redirect to="/admin/dashboard" />
        </Switch>
      </Router>
    </I18nProvider>
  );
};
export default compose(
  withStyles(toastContainerStyle),
  connect((store) => {
    return {
      globalSettings: store.GlobalSettings,
    };
  })
)(App);
